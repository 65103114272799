import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Api } from '../helpers';

interface StateProps {
    charges: {
        Percentage: number,
        Max: number,
        Min: number
    }[]
}

const Pricing = () => {

    const [state, setState] = useState<StateProps>({
        charges: []
    })
    const { charges } = state

    const getFeeCharges = () => {
        Api({
            controller: 'Payments',
            action: 'getFeeCharges'
        }).then((resp: any) => {
            setState({
                ...state,
                charges: resp
            })
            console.log(resp)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getFeeCharges()
        return () => {

        }
    }, [])
    return (<section id='pricing'>
        <Box sx={{ marginTop: 20 }}>
            <Typography variant='h1' sx={{ fontWeight: 'bold', color: "#000", fontSize: 30, marginBottom: 5 }} data-aos="fade-down">Charges</Typography>
            {/* <Typography variant='h1' sx={{ fontWeight: 'bold', color: "#000", fontSize: 20 }} data-aos="fade-down">Players</Typography> */}
            {/* <Typography>SpotPool charges KES 2.50 for each game opened by the player</Typography> */}
            <Typography variant='h1' sx={{ fontWeight: 'bold', color: "#000", fontSize: 20, marginTop: 5 }} data-aos="fade-down">Pool Owners</Typography>
            <Typography>An installation fee of KES 5,000.00, refundable upon termination of service, applies. Pool owners are subject to the following rates:</Typography>

            <Paper elevation={5} data-aos="zoom-in" sx={{ margin: 4, bgcolor: 'background.default', borderTopLeftRadius: 30, borderBottomRightRadius: 30 }} >
                <Grid

                    container spacing={2}
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        // bgcolor: 'background.default',
                        display: 'grid',
                        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                        gap: 2,
                    }}
                >
                    {charges.map(({ Max, Min, Percentage }, index) => (
                        <Grid item xs={12} key={index} sx={{}} >
                            <Paper elevation={24} data-aos="zoom-in" sx={{
                                width: '100%', padding: 2, color: 'orange', bgcolor: 'primary.main', borderTopLeftRadius: 20, borderBottomRightRadius: 20, '&:hover': {
                                    bgcolor: '#b0fc38',
                                    color: 'orangered'
                                }
                            }}>
                                <Typography data-aos="fade-down" variant='body1' sx={{
                                    color: 'orange',
                                    '&:hover': {
                                        color: 'orangered',
                                    }
                                }}>KES {Min} - KES {Max} will be charged {Percentage * 100}%</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

        </Box>
    </section>

    )

}

export default Pricing

