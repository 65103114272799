import React from 'react';
import { Box, Typography } from '@mui/material';

const About = () => {
  return (

    <Box id='about' sx={{ display: 'flex', marginX: 5, marginTop: 10, flexDirection: { xs: 'column', lg: 'row' } }}>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column' }, marginBottom: 5 }}>
        <Typography variant='h5' data-aos="fade-down" sx={{ display: 'flex', alignSelf: 'start', marginBottom: 2, color: 'black' }}>Demo</Typography>
        <Box
          data-aos="zoom-in"
          sx={{
            width: {
              xs: '100%',
              sm: 550,
            },
            height: {
              xs: 200,
              sm: 315,
            },
            '& iframe': {
              width: '100%',
              height: '100%',
              borderRadius: '15px'
            },
            borderRadius: '15px',
            overflow: 'hidden',
            marginBottom: 4
          }}
          dangerouslySetInnerHTML={{
            __html: `
          <iframe 
            src="https://www.youtube.com/embed/xbeM7etoQL4?si=qftKdolI4cH4L5eP" 
            title="" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen>
          </iframe>
        `
          }}
        />
      </Box>

      <Typography data-aos="fade-down" variant='h1' sx={{ width: { sm: '50%' }, fontWeight: 'bold', color: "#000", fontSize: 30, marginBottom: { xs: 2 }, textAlign: { xs: 'start', lg: "center" } }}>Learn About Us</Typography>
      <Typography data-aos="fade-up" variant='body1' sx={{ display: 'flex', textAlign: 'justify', width: { sm: '50%' }, color: "#83888f" }}>
        Welcome to the future of pool table management! At SpotPool, we are revolutionizing the way pool table owners operate their businesses. Say goodbye to the hassle of hiring staff and the outdated coin or key systems. Our innovative automated solution puts you in complete control, effortlessly.
        With our cutting-edge app, pool table owners can manage their tables remotely and efficiently. Clients can now pay for their game time directly through the app, allowing the pool table to unlock automatically for the number of rounds purchased. No more coins, no more keys, no more manual supervision—just seamless, automated fun.
        Our system is designed to enhance convenience for both owners and players, ensuring a smooth, enjoyable experience. Step into the future with SpotPool and discover how easy and profitable managing pool tables can be. Join us in transforming the game room experience, one table at a time.
      </Typography>
    </Box>
  )
}

export default About;
