import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';

interface KeyProps {
    onChangeText: (value: string) => void;
    value: string;
    all: boolean;
    pin: boolean;
    disableAll: boolean;
    noMarginBottom: boolean;
    biometric: boolean;
    onSubmit?: () => void;
}

const CustomKeyboard: FC<KeyProps> = ({
    onChangeText,
    value,
    all,
    pin,
    disableAll,
    noMarginBottom,
    biometric,
    onSubmit
}) => {
    const rows = [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        ['.', 0, 'close'],
        all ? ['All'] : []
    ].filter(a => a.length > 0);
    const dotDisabled = pin && !biometric ? true : !value ? false : value.split('.').length === 2;

    const handleKeyPress = (key: string) => {
        onChangeText(key);
    };

    return (
        <Box sx={{ marginBottom: noMarginBottom ? 0 : 50, display: 'flex', gap: 2, flexDirection: 'column' }}>
            {rows.map((keys: (string | number)[], index) => (
                <Box key={index} sx={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                    {keys.map((key, index0) => (
                        <Button
                            onClick={() => handleKeyPress(key.toString())}
                            disabled={disableAll || (dotDisabled && key === '.')}
                            style={{ opacity: key === '.' && dotDisabled ? 0.5 : 1 }}
                            key={index0}
                            sx={{ color: 'black', fontWeight: 1500, fontSize: 25 }}
                        >
                            {key === 'close' || (key === '.' && biometric) ? (
                                <span>{key === '.' ? 'finger-print-outline' : <BackspaceOutlinedIcon />}</span>
                            ) : (
                                <span style={{ fontWeight: key === 'All' ? 'normal' : 'bold' }}>{key.toString()}</span>
                            )}
                        </Button>
                    ))}
                </Box>
            ))}

            <Button
                variant='contained'
                onClick={onSubmit}
                sx={{
                    paddingX: 10,
                    borderRadius: 10,
                    width: 100,
                    display: 'flex',
                    alignSelf: 'center'
                }}>Next</Button>

        </Box>
    );
};

export default CustomKeyboard;