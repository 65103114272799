import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery,
  Avatar,
  useScrollTrigger,
  List,
  ListItem,
  Button
} from "@mui/material";
import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';

import logo from '../assets/images/pool.jpeg';
import MenuIcon from '@mui/icons-material/Menu';
import theme from "../theme";

import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { useNavigate } from "react-router-dom";
import Auth from "../components/Auth/index";

interface StyledAppBarProps {
  scrolled: boolean;
  theme: Theme;
}

interface StateProps {
  isMenuOpen: boolean;
  active: number | null;
}

const StyledAppBar = styled(AppBar)<StyledAppBarProps>(({ theme, scrolled }) => ({
  backgroundColor: scrolled ? 'white' : 'transparent',
  backdropFilter: 'blur(10px)',
  boxShadow: scrolled ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  transition: theme.transitions.create(['background-color', 'backdrop-filter', 'box-shadow'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
}));

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return React.cloneElement(children, {
    scrolled: trigger,
  });
}

const Hero = (props: any) => {
  // const nav = [
  //   { link: "/#home", label: "Home" },
  //   { link: "/#about", label: 'About Us' },
  //   { link: "/#pricing", label: 'Pricing' },
  //   { link: "/#faqs", label: 'Faqs' },
  //   { link: "/#contact", label: 'Contact' }
  // ];

  const nav = [
    { path: "/#home", label: "Home", icon: <HomeIcon /> },
    { path: "/#about", label: "About", icon: <InfoIcon /> },
    { path: "/#pricing", label: "Charges", icon: <MonetizationOnIcon /> },
    { path: "/#faqs", label: "FAQs", icon: <HelpIcon /> },
    { path: "/#contact", label: "Contact", icon: <ContactSupportIcon /> },
  ];

  const [state, setState] = useState<StateProps>({
    isMenuOpen: false,
    active: null,
  });

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/auth')
  };

  const { isMenuOpen, active } = state;
  const isMobile = useMediaQuery('(max-width:700px)');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuIcon = () => {
    setState({
      ...state,
      isMenuOpen: true,
    });
  };

  const handleNavClicked = (index: number) => {
    setState({
      ...state,
      active: index,
      isMenuOpen: false
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      isMenuOpen: false
    });
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://play-lh.googleusercontent.com/f0NMGaEDuPZvVpRdxdkn0PoQXLz7dBs-vH5zCiBhW9cgv9FszhdMfr-EQGJHJ2qJXFM=w2560-h1440-rw)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      id='home'
      data-aos="fade-down"
    >
      <HideOnScroll {...props} >
        <StyledAppBar position="fixed" theme={theme} scrolled={scrolled}>
          <CssBaseline />
          <Toolbar
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 0,
              alignItems: 'center',
              padding: { xs: 1, sm: 3, md: 4 },
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }} >
              <Avatar src={logo}
                sx={{
                  height: 30,
                  width: 30,
                  objectFit: 'contain'
                }}
                alt='SpotPool' />
              <Typography variant="h5" component="div"
                sx={{ flexGrow: 1, fontWeight: 600, color: 'primary.main', marginLeft: 1 }}>
                SpotPool
              </Typography>
            </Box>

            {isMobile && (
              <Box>
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={{
                    padding: 1,
                    borderRadius:20
                  }}
                >
                  Get Started
                </Button>
                <IconButton
                  size="large"
                  edge="end"
                  color={scrolled ? "primary" : "inherit"}
                  aria-label="menu"
                  onClick={handleMenuIcon}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}

            {(isMobile || isMenuOpen) && (
              <Menu
                open={isMenuOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{ color: 'blueviolet' }}
              >
                {nav.map((item, i) => (
                  <MenuItem
                    key={i}
                    selected={active === i}
                    onClick={() => handleNavClicked(i)}
                    sx={{ bgcolor: 'transparent' }}
                  >
                    <a href={item.path}
                      style={{
                        textDecoration: 'none',
                        // color: scrolled ? 'black' : 'primary.main',
                        color: 'primary.main'
                      }}>
                      <IconButton
                        key={i}
                        size="large"
                        edge="end"
                        color={scrolled ? "primary" : "primary"}
                        aria-label={item.label}
                        href={item.path}
                        onClick={handleMenuIcon}
                      >
                        {item.icon}
                      </IconButton>

                    </a>
                  </MenuItem>
                ))}

                {/* {nav.map((link, index) => (
                   <IconButton
                     key={index}
                     size="large"
                     edge="end"
                     color={scrolled ? "primary" : "inherit"}
                     aria-label={link.label}
                     href={link.path}
                     onClick={handleMenuIcon}
                   >
                     {link.icon}
                   </IconButton>
                 ))} */}
              </Menu>
            )}

            {!isMobile && (
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {nav.map((item, i) => (
                  <a href={item.path}
                    key={i}
                    onClick={() => setState({ ...state, active: i })}
                    style={{
                      fontSize: '1.3rem',
                      padding: '8px 20px',
                      borderRadius: '9999px',
                      cursor: 'pointer',
                      margin: '5px 10px',
                      textDecoration: 'none',
                      color: scrolled ? (i === active ? '#006B46' : 'black') : (i === active ? '#006B46' : '#fff'),
                      borderBottom: i === active ? '2px solid #006B46' : 'none'
                    }}
                  >
                    {item.label}
                  </a>
                ))}
                <Button sx={{ textTransform: 'none', fontSize:20, borderRadius:20 }} variant='contained' onClick={handleButtonClick}>Open Pool</Button>
              </Box>
            )}
          </Toolbar>
        </StyledAppBar>
      </HideOnScroll>

      <Box sx={{ display: 'flex', gap: 7, flexDirection: 'column' }} data-aos="zoom-in">
        <Box>
          <Typography data-aos="fade-down" variant="h3" component="h1" gutterBottom sx={{ color: '#b0fc38', fontSize: { xs: 35, md: 40 }, marginBottom: { xs: 3 }, fontWeight: 'bold' }}>
            Revolutionize Your Pool Table Management
          </Typography>

          <Typography data-aos="fade-up" variant="h5" component="h5" gutterBottom sx={{ color: { xs: 'black', md: 'white' } }}>Unlock the Future of Pool Table Management with our Innovative Digital Token System!
          </Typography>
        </Box>

        <Box data-aos="fade-down" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ textAlign: 'left', color: { xs: '#b0fc38', md: 'black' } }} data-aos="fade-up"> Key Features:</Typography>
          <List sx={{ display: 'inline-block', textAlign: 'left' }} data-aos="zoom-in">
            {['No More Coins or Keys', 'Remote Management', 'Automated Unlocking', 'Direct Payment via App'].map((benefit, index) => (
              <ListItem data-aos="fade-up" key={index} sx={{ display: 'list-item', listStyleType: 'disc', paddingLeft: '20px', color: '#b0fc38' }}>
                <Typography data-aos="fade-up" sx={{ color: { xs: 'white', md: '#b0fc38' } }}>{benefit}</Typography>
              </ListItem>
            ))}
          </List>



          <a href='/#contact'>
            <Button
              data-aos="fade-up"
              variant="contained"
              color="primary"
              sx={{ marginTop: '15px', fontSize: '12px', width: 130 }}
            >
              Reach Out
            </Button>
          </a>
        </Box>



      </Box>

    </Box >
  );
}

export default Hero;
