import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { FC } from "react"
import Show from "./show"
import { Add, SentimentDissatisfied } from "@mui/icons-material"

interface Props {
    loading?: boolean
    text?: string,
    oopTxt?: string
    button?: {
        text: string,
        onPress: () => void
    },
    searchValue?: string | null,
    icon?: string
}
const EmptyView: FC<Props> = ({
    loading,
    text,
    oopTxt,
    button,
    searchValue,
    icon
}) => {
    return (
        <Box sx={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <Show>
                <Show.When isTrue={loading}>
                    <CircularProgress />
                </Show.When>
                <Show.Else>
                    <Show>
                        <Show.When isTrue={Boolean(oopTxt)}>
                            <Typography sx={{}}>{oopTxt}</Typography>
                        </Show.When>
                        <Show.Else>
                            {
                                icon ||
                                <SentimentDissatisfied />
                            }
                        </Show.Else>
                    </Show>
                    <Typography sx={{}}> {searchValue ? 'No results' : (text || 'No data')}</Typography>
                    {
                        button && !searchValue ?
                            <Button
                                onClick={button.onPress}
                                sx={{}}
                            >
                                <Add />
                                <Typography sx={{}} >{button.text}</Typography>
                            </Button> :
                            null
                    }
                </Show.Else>
            </Show>
        </Box>
    )
}

export default EmptyView