import { Cancel } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { QrReader } from 'react-qr-reader';
import Show from '../common/show';

interface Props {
    onClose: () => void;
    onScan: (url: any) => void;
}

const QrScanner: React.FC<Props> = ({
    onClose,
    onScan
}) => {
    const [isSupported, setIsSupported] = useState(true);
    const [scanning, setScanning] = useState(true);

    useEffect(() => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            setIsSupported(false);
        }

    }, []);

    const handleScan = useCallback((data: { text: string } | null) => {
        if (data?.text && scanning) {
            onScan(data.text);
            setScanning(false);
        }
    }, [scanning, onScan]);



    if (!isSupported) {
        return <p>QR scanning is not supported on this device.</p>;
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                bgcolor: '#000',
                flexDirection: 'column'
            }}
        >
            <IconButton onClick={onClose}>
                <Cancel sx={{ color: '#fff' }} />
            </IconButton>
            <Box
                sx={{
                    height: '90vh',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Show>
                    <Show.When isTrue={scanning}>
                        <QrReader
                            onResult={handleScan as any}
                            constraints={{
                                facingMode: 'environment'
                            }}
                            videoContainerStyle={{}}
                        />
                    </Show.When>
                    <Show.Else>
                        <Box sx={{
                            mt: 50
                        }}>
                            <CircularProgress sx={{ color: '#fff' }} />
                            <Typography sx={{ color: '#fff' }}>Checking for place</Typography>
                        </Box>
                    </Show.Else>
                </Show>

            </Box>
        </Box>
    );
};

export default QrScanner;
