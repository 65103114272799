import { Box, List, ListItemButton, Typography } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { Connect, mapDispatchToProps } from "../../Redux"
import EmptyView from "../common/EmptyView"
import Show from "../common/show"
import moment from 'moment'
import { Api } from "../../helpers"
import NotificationModal from "./modal"

interface Props {
    setRedux?: (data: any) => void
}
export interface AlertProps {
    date: Date
    title: string
    message: string
    read: boolean
    viewed: boolean
    type: string
    _id: string
}

interface StateProps {
    data: AlertProps[]
    loading: boolean
    clickedItem: AlertProps | null
}
const Notifications: FC<Props> = ({
    setRedux
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        loading: true,
        clickedItem: null
    })
    const { loading, data, clickedItem } = state
    const getNotifications = () => {
        const afterLoad = {
            loading: false,
            refreshing: false
        }
        Api({
            controller: 'Notifications',
            action: 'getNotifications'
        }).then((resp: any) => {
            setState({
                ...state,
                data: resp,
                ...afterLoad
            })

        }).catch((err) => {
            setState({
                ...state,
                ...afterLoad
            })
        })
    }
    useEffect(() => {
        getNotifications()
        if (setRedux)
            setRedux({ unViewedNotifications: false })
        return () => {

        }
    }, [])

    return (
        <Box sx={{
            flex: 1
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 2
            }}>
                <Typography sx={{
                    color: '#000',
                    fontSize: 25,
                    fontWeight: 'bold'
                }}>Notifications</Typography>
            </Box>
            <Show>
                <Show.When isTrue={loading}>
                    <EmptyView loading={loading} />
                </Show.When>
                <Show.Else>
                    <List>
                        {
                            data.map((a, i) => (
                                <ListItemButton
                                    sx={{
                                        bgcolor: a.read ? 'transparent' : '#ECECEC',
                                        borderBottom: `1px solid ${i === data.length - 1 ? 'transparent' : '#C7C7C7'}`,
                                        p: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    onClick={() => {
                                        let Data = [...data]
                                        Data[i] = { ...Data[i], read: true }
                                        setState({
                                            ...state,
                                            data: Data,
                                            clickedItem: a
                                        })
                                    }}
                                >
                                    <Typography sx={{ fontSize: 20, color: '#000', fontWeight: 'bold' }}> {a.title}</Typography>
                                    <Typography> {moment(a.date).fromNow()}</Typography><br />
                                    <Typography sx={{
                                        color: '#000',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitLineClamp: 2
                                    }}>
                                        {a.message}
                                    </Typography>

                                </ListItemButton>
                            ))
                        }
                    </List>
                </Show.Else>
            </Show>
            <Show>
                <Show.When
                    isTrue={Boolean(clickedItem)}
                >
                    <NotificationModal
                        close={() => setState({ ...state, clickedItem: null })}
                        notification={clickedItem as AlertProps}
                    />
                </Show.When>
            </Show>
        </Box>
    )
}

export default Connect(null, mapDispatchToProps)(Notifications)