import {
    AppBar,
    Toolbar,
    CssBaseline,
    Typography,
    Box,
    Avatar,
    useScrollTrigger
} from "@mui/material";
import { styled } from '@mui/system';
import React from 'react'
import { Theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/pool.jpeg';
import theme from "../../theme";


interface StyledAppBarProps {
    scrolled: boolean;
    theme: Theme;
}


const StyledAppBar = styled(AppBar)<StyledAppBarProps>(({ theme, scrolled }) => ({
    backgroundColor: scrolled ? 'white' : 'transparent',
    backdropFilter: 'blur(10px)',
    boxShadow: scrolled ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
    transition: theme.transitions.create(['background-color', 'backdrop-filter', 'box-shadow'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
    }),
}));

function HideOnScroll(props: any) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return React.cloneElement(children, {
        scrolled: trigger,
    });
}


const Policies = (props: any) => {
    const privacyPolicies = [
        {
            title: "Data Collection",
            description: "We collect user data such as contact details and payment information to manage pool table usage and facilitate transactions through our app."
        },
        {
            title: "Information Usage",
            description: "The collected data is used to manage pool table sessions, process payments, and provide a seamless user experience."
        },
        {
            title: "Data Security",
            description: "We implement industry-standard security measures to protect user data and ensure it is not accessed or disclosed without authorization."
        },
        {
            title: "Third-Party Access",
            description: "User data is not shared with third parties without explicit consent, except as required by law or to comply with legal obligations."
        },
        {
            title: "Cookies",
            description: "We may use cookies to enhance user experience and gather analytics data to improve our services. Users can manage cookie preferences."
        },
        {
            title: "Data Retention",
            description: "User data is retained only for as long as necessary to fulfill the purposes outlined in this privacy policy or as required by law."
        },
        {
            title: "Opt-Out",
            description: "Users have the option to opt-out of certain data collection and processing activities, subject to limitations outlined in the privacy policy."
        },
        {
            title: "Children's Privacy",
            description: "Our services are not directed at children under the age of 13, and we do not knowingly collect personal information from them."
        },
        {
            title: "Updates to Privacy Policy",
            description: "We reserve the right to update this privacy policy periodically. Users will be notified of any changes via email or through the app."
        },
        {
            title: "User Rights",
            description: "Users have the right to access, rectify, and delete their personal information. Requests can be made through the app or by contacting us."
        },
        {
            title: "International Data Transfers",
            description: "User data may be transferred and processed in countries outside of the user's country of residence, subject to applicable laws and regulations."
        },
        {
            title: "Data Controller",
            description: "The data controller responsible for the processing of personal data is SpotPool, located at Nyeri,Kenya."
        },
        {
            title: "Data Protection Officer",
            description: "For inquiries regarding data protection or to exercise privacy rights, users can contact our Data Protection Officer at info@spotpool.com."
        },
        {
            title: "User Consent",
            description: "By using our services, users consent to the collection, processing, and storage of their personal information as outlined in this policy."
        },
        {
            title: "Contact Information",
            description: "For questions or concerns regarding this privacy policy, users can contact us at info@spotpool.com."
        },
        {
            title: "Terms of Service",
            description: "By using our services, users agree to abide by our Terms of Service, which govern the use of our platform."
        },
        {
            title: "Data Accuracy",
            description: "Users are responsible for ensuring the accuracy of the information provided. We are not liable for any inaccuracies or errors."
        },
        {
            title: "Data Deletion",
            description: "Upon request, user data will be deleted from our systems, unless retention is required by law or for legitimate business purposes."
        },
        {
            title: "Data Breach Notification",
            description: "In the event of a data breach that compromises user data, affected users will be notified promptly and provided with guidance."
        },
        {
            title: "User Feedback",
            description: "We welcome user feedback regarding privacy concerns and strive to address them promptly and transparently."
        }
    ];

    return (
        <>
            {/* <Card
                sx={{
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 6,
                    alignItems: 'center',
                    backgroundColor: 'silver',
                    padding: { xs: 2, md: 3 },
                    justifyContent: 'space-between'
                }}
            >
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={logo}
                            style={{ height: 30, width: 30, objectFit: "contain" }}
                            alt="SpotPool"
                        />
                        <Typography
                            sx={{
                                color: "#7308C7",
                                alignSelf: "flex-start",
                                marginLeft: 1,
                                fontSize: { xs: 20, sm: 25 },
                                fontWeight: 700,
                                fontFamily: "Roboto"
                            }}
                        >
                            SpotPool
                        </Typography>
                    </Box>
                </Link>
            </Card> */}

            <HideOnScroll {...props}>
                <StyledAppBar position="fixed" scrolled={false} theme={theme}>
                    <AppBar position="fixed">

                        <CssBaseline />
                        <Toolbar
                            sx={{
                                position: 'fixed',
                                top: 0,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 6,
                                alignItems: 'center',
                                backgroundColor: 'primary.main',
                                padding: { xs: 2, sm: 4 },
                                justifyContent: 'space-between'
                            }}
                        >
                            <Link
                                to='/'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    textDecoration: 'none'
                                }} >

                                <Avatar src={logo}
                                    sx={{
                                        height: 30,
                                        width: 30,
                                        objectFit: 'contain'
                                    }}
                                    alt='SpotPool' />
                                <Typography variant="h5" component="div"
                                    sx={{ flexGrow: 1, fontWeight: 600, color: 'silver', marginLeft: 1 }}>
                                    SpotPool
                                </Typography>

                            </Link>

                        </Toolbar>
                    </AppBar>

                </StyledAppBar>
            </HideOnScroll>

            <Box sx={{ marginTop: { xs: 13, md: 20 }, marginBottom: 10 }}>
                <Typography variant='h2' sx={{ fontFamily: "Roboto", fontSize: { xs: 20 }, fontWeight: 'bold', marginBottom: 4, color: 'black' }}>Privacy and Policies</Typography>
                {privacyPolicies.map((item, index) => {
                    return (
                        <Box key={index} sx={{ alignItems: 'flex-start', justifyContent: 'flex-start', marginLeft: { xs: 3, md: 30 }, marginRight: { xs: 3 }, marginBottom: { xs: 2 } }}>
                            <Typography variant='h4' sx={{ display: 'flex', alignContent: "flex-start", justifyContent: 'flex-start', fontSize: { xs: 15, sm: 16 }, fontFamily: "Roboto", fontWeight: 'bold', color: 'black' }}>{item.title}</Typography>
                            <Typography variant='body1' sx={{ display: 'flex', alignContent: "flex-start", textAlign: 'start', fontSize: { xs: 13.5, sm: 15 }, fontFamily: "Roboto" }}>{item.description}</Typography>
                        </Box>
                    );
                })}
            </Box>
        </>
    );
}

export default Policies;
