import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Routes from './components/Navigation';
import theme from './theme';
import 'react-toastify/dist/ReactToastify.css';
import DynamicModal from './components/ModalContext/DynamicModal';
import { ModalProvider } from './components/ModalContext';

const App = () => {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <Routes />
          <DynamicModal />
        </ModalProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
