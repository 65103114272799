import axios from "axios";
import { store } from "../Redux";
import { Crypt } from "hybrid-crypto-ts";
import { public_encryption_key, url } from "../configs";
import { parsePhoneNumber } from 'libphonenumber-js'
import eventBus from '../configs/eventsBus'



const crypt = new Crypt();
export const Api = (data: any) => new Promise((resolve, reject) => {
    const jwtToken = store.getState().jwtToken,
        encrypted = crypt.encrypt(
            public_encryption_key,
            JSON.stringify({...data, isWeb:true}),
        );
    axios.post(url, encrypted, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': "Bearer " + jwtToken
        }
    }).then((resp) => {
        resolve(resp.data)
    }).catch((err: any) => {
        try {
            if (err.response?.status == 403)
                eventBus.dispatch('routes', { action: 'logOut' })
            else
                reject(err)
        } catch (err) {
            reject(err)
        }
    })
}),
    showHideLoader = (show?: boolean, message?: string) => {
        if (show)
            eventBus.dispatch('loader', { text: message })
        else
            eventBus.dispatch('loader', { action: 'close' })
    },
    checkWebCameraPermission = () => {
        return new Promise((resolve, reject) => {

            navigator.mediaDevices.getUserMedia({ video: true })
                .then((stream) => {
                    resolve(true);
                    stream.getTracks().forEach(track => track.stop());
                })
                .catch((err) => {
                    console.error('Camera permission denied or error occurred', err);
                    alert('Please allow camera access in your browser settings.');
                    reject('Error');
                });
        });
    },
    getFormattedPhone = (phone: string) => {
        try {
            return parsePhoneNumber(phone).format('INTERNATIONAL')
        } catch (err) {
            return false
        }
    },
    numberWithCommas = (number: number | string) => {
        if (!number || number == 'None')
            number = 0
        number = parseFloat(number.toString())
        let numberString = (number as number).toFixed(2).toString(); // toFixed(2) adds two decimal places

        const parts = numberString.split(".");
        let integerPart = parts[0];
        const decimalPart = parts.length > 1 ? "." + parts[1] : "";

        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        numberString = integerPart + decimalPart;

        return numberString;
    },
    getAbbr = (name: string) => {
        const split = name.split(' ')
        return split[1] ? (split[0][0] + '' + split[1][0]).toUpperCase() : name.substring(0, 2).toUpperCase()
    }


