import { FC, Fragment, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Layout from '../../Layout/index';
import Policies from "../Terms_Conditions/Policies";
import Terms from "../Terms_Conditions/Terms";
import Auth from "../Auth";
import Screens from "./tabs";
import Topup from "../Profile/Topup";
import Editprofile from "../Profile/Editprofile";
import Deposits from "../Profile/Deposits";
import CheckDeposit from "../Profile/CheckDeposit";
import Tables from "../Home/tables";
import { Connect, mapDispatchToProps, mapStateToProps } from "../../Redux";
import eventBus from "../../configs/eventsBus";
import Loader from "./loader";
import AlertDialogSlide from "./alert";
import { toast, ToastContainer } from 'react-toastify';
import { Api } from "../../helpers";
import { UserProps } from "../../types/user";

interface Props {
    loggedIn?: boolean
    logOut?: () => void
    setRedux?: (data: any) => void
    User?: UserProps
}

const Routes0: FC<Props> = ({
    loggedIn,
    User,
    logOut,
    setRedux
}) => {
    const freePages = [
        { path: '/', element: <Layout /> },
        { path: '/home', element: <Screens /> },
        { path: '/policies', element: <Policies /> },
        { path: '/terms_and_conditions', element: <Terms /> },
        { path: '/auth', element: <Auth /> },
    ];
    const loggedInPages = [
        { path: '/', element: <Layout /> },
        { path: '/home', element: <Screens /> },
        { path: '/topup', element: <Topup /> },
        { path: '/edit_profile', element: <Editprofile /> },
        { path: '/deposits', element: <Deposits /> },
        { path: '/check_deposit', element: <CheckDeposit /> },
        { path: '/tables', element: <Tables /> }
    ];
    const pages = loggedIn ? loggedInPages : freePages;
    const getUserData = () => {
        Api({
            controller: 'Users',
            action: 'getUserData'
        }).then((user: any) => {
            if (setRedux)
                setRedux({ User: { ...User, ...user } })
        })
    }
    useEffect(() => {
        eventBus.on('routes', (info: any) => {
            let { action, type, message } = info.detail
            if (logOut && action === 'logOut') {
                logOut()
                window.location.href = '/'
            }
            else if (action === 'toastMsg')
                (toast as any)[type](message)
            else if (action === 'getUserData')
                getUserData()
        })
        return () => {
            eventBus.remove('routes',{})
        }
    }, [])

    return (
        <Fragment>
            <Routes>
                {pages.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Loader />
            <AlertDialogSlide />
            <ToastContainer />
        </Fragment>
    );
}

export default Connect(mapStateToProps, mapDispatchToProps)(Routes0);
