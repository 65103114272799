import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { InputProps } from '../../types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';




interface EditprofileProps {
    // editProfileBack: () => void;
}

interface StateProps {


    submitting: boolean,
    inputs: InputProps[],
    country: {
        flag: string,
        code: string
    },

}

const Editprofile: React.FC<EditprofileProps> = () => {

    const initialInputs: InputProps[] = [
        { label: 'Username', value: '', field: 'Username' },
        { label: 'Phone', value: '', field: 'phone' },
        { label: 'Till Number', value: '', field: 'till_number' }
    ]

    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        submitting: false,
        country: {
            flag: '🇰🇪',
            code: '254'
        },
    })

    const { inputs, submitting } = state;

    const navigate = useNavigate();

    const handleOnChange = (value: string, country: any) => {
        setState({
            ...state,
            country: {
                flag: country.countryCodeEmoji,
                code: country.dialCode
            }
        });
    };

    const changeInput = (input: string, obj: any) => {
        let Input = { ...(state as any)[input] }
        Input = { ...Input, ...obj }
        setState({
            ...state,
            [input]: Input,
            submitting: false
        })
    }

    return (
        <Box sx={{ marginX: 3 }}>

            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 11, marginTop: 3 }}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackOutlinedIcon />
                </IconButton>

                <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Edit Profile</Typography>

            </Box>

            <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 26 }}>
                {inputs.map((input, i: number) => {
                    const { label, value, error, field, type } = input
                    return (
                        <Grid
                            item
                            xs={12}

                            key={field}
                        >
                            <TextField
                                variant='standard'
                                autoComplete="given-name"
                                name={field}
                                required
                                fullWidth
                                label={label}
                                type={field === 'phone' ? 'tel' : 'text'}
                                autoFocus
                                error={Boolean(error)}
                                helperText={error}
                                onFocus={() => changeInput(field, { error: null })}
                                InputProps={{
                                    startAdornment: (
                                        field === 'phone' && (
                                            <InputAdornment position="start">
                                                <PhoneInput
                                                    country={'ke'}
                                                    value={state.country.code}
                                                    onChange={(value, country) => handleOnChange(value, country)}

                                                    inputStyle={{ border: 'none', outline: 'none', width: 85, backgroundColor: 'transparent' }}
                                                    dropdownStyle={{ top: 'auto', bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'start', width: 380 }}
                                                    buttonStyle={{
                                                        backgroundColor: 'transparent', border: 'none', transition: 'none',
                                                    }}

                                                />



                                            </InputAdornment>)
                                    ),


                                }}

                                sx={{ backgroundColor: 'transparent' }}

                            />
                        </Grid>
                    )
                })}
            </Grid>

            <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 4, textTransform: 'none', borderRadius: 30, paddingX: 5 }}
                loading={submitting}
            >
                Update
            </LoadingButton>

        </Box>
    )
}

export default Editprofile