import { Box, Typography, Grid, TextField, Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface StateProps {
    expanded: boolean | string;
}

const Faqs = () => {

    const [state, setState] = useState<StateProps>({
        expanded: false
    });

    const handleChange = (isExpanded: boolean, panel: string): void => {
        const expand = isExpanded ? panel : false;
        setState({
            ...state,
            expanded: expand
        });
    };



    const faqs = [
        {
            summary: "What is a SpotPool?",
            description: " SpotPool is an organization with innovative automated solution for pool table management. It revolutionizes the way pool table owners operate their businesses by eliminating the need for staff, outdated coin systems, and manual supervision."
        },
        {
            summary: "How Does the SpotPool App Revolutionize Pool Table Management?",
            description: "With the SpotPool app, owners can manage their tables remotely and efficiently. Clients can pay for their game time directly through the app, which automatically unlocks the pool table for the purchased rounds. SpotPool enhances convenience for both owners and players, ensuring a seamless and enjoyable experience."
        },
        {
            summary: "What is the benefit of a using our system?",
            description: "Our system streamlines pool table management by eliminating coins and keys, enabling remote control, and automating payments and table unlocking. It enhances convenience, boosts efficiency, and provides a seamless experience for both owners and players."
        },
        {
            summary: "How do I get the SpotPool system installed in my pool table(s)?",
            description: "To get the SpotPool system installed in your pool table(s), simply reach out to our team via our website or app to discuss your needs. We'll schedule a convenient time for our technicians to install the system. During the installation, our team will set up the necessary hardware and software, and provide training on how to use the SpotPool app. Once everything is set up, you can start managing your pool tables remotely and enjoy the benefits of automated operations."
        }
    ];

    const { expanded } = state

    return (
        <Box id='faqs'>
            <Box sx={{ marginY: 15 }}>
                <Typography variant='h3' data-aos="fade-down" sx={{ marginBottom: 5, fontSize: { xs: 27, sm: 32, md: 35 }, color: 'black' }}>FAQs</Typography>
                <Grid data-aos="fade-up" container spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {faqs.map((faq, index) => (
                        <Grid item key={index} xs={12} sm={6} md={6} sx={{ marginX: { xs: 1, sm: 5 } }}>
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={(event, isExpanded) => handleChange(isExpanded, `panel${index}`)}
                                sx={{ width: '100%', borderRadius: 100 }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{ backgroundColor: 'primary.main' }}
                                >
                                    <Typography data-aos="fade-down" sx={{ color: '#fff' }}>{faq.summary}</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Typography variant='body2' sx={{ textAlign: 'start', color: 'GrayText' }} data-aos="fade-down">{faq.description}</Typography>
                                </AccordionDetails>


                            </Accordion>

                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}

export default Faqs