import { Button } from '@mui/material'
import React, { useState, FC, useEffect } from 'react'
import { loginType } from '../Auth'
import { Api, showHideLoader } from '../../helpers'

interface Props {
    User?: any,
    loginWith: loginType,
    setError: (err: string | null) => void,
    Value?: string
}

interface StateProps {
    counter: number
}

const Counter: FC<Props> = ({
    User,
    loginWith,
    setError,
    Value
}) => {
    let interval: any
    const [state, setState] = useState<StateProps>({
        counter: 0
    })
    const { counter } = state;
    const startCounter = () => {
        interval = null;
        if (counter > 0) {
            interval = setInterval(() => {
                setState({
                    ...state,
                    counter: counter - 1
                });
            }, 1000);
        } else if (counter === 0)
            clearInterval(interval);
    }

    const resendCode = () => {
        setError(null)
        setState({
            ...state,
            counter: 45,
        })
        startCounter();
        showHideLoader(true, 'Resending code...')
        console.log('resending code ...')
        Api({
            controller: 'Users',
            action: 'resendCode',
            userId: User?._id
        }).then((resp: any) => {
            const { message, status } = resp
            if (status === 'error')
                setError(message)
            showHideLoader(false)
        }).catch(e => {
            showHideLoader(false)
        })
    }

    useEffect(() => {
        startCounter();
        return () => {
            clearInterval(interval);
        }
    }, [counter, interval])

    return (
        <Button
            onClick={resendCode}
            disabled={Boolean(counter)}
            sx={{
                color: counter ? 'black' : 'primary.dark',
                textTransform: 'none'
            }}>
            {counter ? `${'00 : ' + (counter < 10 ? ('0' + counter) : counter)}` : 'Resend code'}
        </Button>
    )
}

export default Counter;
