import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface ModalInfo {
    text: string;
}

interface ModalContextType {
    modal: string | null;
    info: ModalInfo | null;
    showModal: (modal: string, info: ModalInfo) => void;
    hideModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

interface ModalProviderProps {
    children: ReactNode; // Define the children prop type here
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modal, setModal] = useState<string | null>(null);
    const [info, setInfo] = useState<ModalInfo | null>(null);

    const showModal = (modal: string, info: ModalInfo) => {
        setModal(modal);
        setInfo(info);
    };

    const hideModal = () => {
        setModal(null);
        setInfo(null);
    };

    useEffect(() => {
        const handleModalEvent = (data: { modal: string, info: ModalInfo }) => {
            if (data.modal) {
                showModal(data.modal, data.info);
            } else {
                hideModal();
            }
        };


        return () => {
        };
    }, []);

    return (
        <ModalContext.Provider value={{ modal, info, showModal, hideModal }}>
            {children}
        </ModalContext.Provider>
    );
};
