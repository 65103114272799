import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Box,
  Avatar,
  useScrollTrigger
} from "@mui/material";
import React from 'react';
import theme from "../../theme";
import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import logo from '../../assets/images/pool.jpeg'
import { Link } from "react-router-dom";

interface StyledAppBarProps {
  scrolled: boolean;
  theme: Theme;
}


const StyledAppBar = styled(AppBar)(({ theme, scrolled }: StyledAppBarProps) => ({
  backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
}));

function HideOnScroll(props: any) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return React.cloneElement(children, {
    scrolled: trigger,
  });
}


const terms_and_conditions = [
  {
    title: "Terms and Conditions",
    sections: [
      {
        subtitle: "1. Use of SpotPool",
        content: (
          <>
            <Typography variant="body1">
              1.1 Access and License: We grant you a non-exclusive, non-transferable, revocable license to use SpotPool in accordance with these Terms.
            </Typography>
            <Typography variant="body1">
              1.2 Registration: To use SpotPool, you may need to register and create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </Typography>
            <Typography variant="body1">
              1.3 Prohibited Conduct: You agree not to:
              <ul>
                <li>Use SpotPool for any illegal purpose or in violation of any laws.</li>
                <li>Attempt to gain unauthorized access to SpotPool or any accounts, computer systems, or networks associated with SpotPool.</li>
                <li>Modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of SpotPool.</li>
                <li>Use any automated system, including "robots," "spiders," or "offline readers," to access SpotPool.</li>
                <li>Interfere with or disrupt the operation of SpotPool or the servers or networks used to make SpotPool available.</li>
              </ul>
            </Typography>
          </>
        ),
      },
      {
        subtitle: "2. SpotPool Services",
        content: (
          <>
            <Typography variant="body1">
              2.1 Automated Management: SpotPool provides an automated system for managing pool tables, including remote management, automated unlocking, and payment processing.
            </Typography>
            <Typography variant="body1">
              2.2 Availability: We strive to ensure the availability of SpotPool but do not guarantee that SpotPool will be available at all times. We may suspend or terminate access to SpotPool for maintenance, repairs, or any reason, without prior notice.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "3. Fees and Payments",
        content: (
          <>
            <Typography variant="body1">
              3.1 Fees: The use of SpotPool may be subject to fees, which will be disclosed to you prior to using SpotPool.
            </Typography>
            <Typography variant="body1">
              3.2 Payment Processing: SpotPool facilitates payments for game time directly through the app. Payments are processed securely and in accordance with our Privacy Policy.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "4. Privacy Policy",
        content: (
          <>
            <Typography variant="body1">
              4.1 Privacy: Your use of SpotPool is subject to our Privacy Policy, which explains how we collect, use, and disclose your information. By using SpotPool, you consent to the collection and use of your information as described in our Privacy Policy.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "5. Intellectual Property",
        content: (
          <>
            <Typography variant="body1">
              5.1 Ownership: SpotPool, including all intellectual property rights therein, are and shall remain our property or the property of our licensors.
            </Typography>
            <Typography variant="body1">
              5.2 Use of Intellectual Property: You may not use any of our trademarks, logos, or other proprietary graphics without our prior written consent.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "6. Limitation of Liability",
        content: (
          <>
            <Typography variant="body1">
              6.1 Disclaimer: SpotPool is provided on an "as is" and "as available" basis. We make no warranties or representations about SpotPool, and disclaim all warranties, whether express, implied, or statutory, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </Typography>
            <Typography variant="body1">
              6.2 Limitation of Liability: In no event shall we be liable to you or any third party for any indirect, incidental, special, consequential, or punitive damages, including lost profits, arising out of or in connection with these Terms or your use of SpotPool.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "7. Modifications to Terms",
        content: (
          <>
            <Typography variant="body1">
              7.1 Changes: We may modify these Terms at any time by posting the modified Terms on SpotPool. Your continued use of SpotPool after such modifications will constitute your acknowledgment of the modified Terms and agreement to abide and be bound by the modified Terms.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "8. Termination",
        content: (
          <>
            <Typography variant="body1">
              8.1 Termination: We may terminate or suspend your access to SpotPool immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "9. Governing Law",
        content: (
          <>
            <Typography variant="body1">
              9.1 Governing Law: These Terms shall be governed by and construed in accordance with the laws of Republic of Kenya, without regard to its conflict of law provisions.
            </Typography>
          </>
        ),
      },
      {
        subtitle: "10. Contact Us",
        content: (
          <>
            <Typography variant="body1">
              10.1 Contact: If you have any questions about these Terms, please contact us at info@spotpool.com.
            </Typography>
          </>
        ),
      },
    ],
  },
];


const Terms = (props: any) => {


  return (
    <Box>
      <HideOnScroll {...props}>
        <StyledAppBar position="fixed" scrolled={false} theme={theme}>
          <AppBar position="fixed">

            <CssBaseline />
            <Toolbar
              sx={{
                position: 'fixed',
                top: 0,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 6,
                alignItems: 'center',
                backgroundColor: 'primary.main',
                padding: { xs: 2, sm: 4 },
                justifyContent: 'space-between'
              }}
            >
              <Link
                to='/'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  textDecoration: 'none'
                }} >
                <Avatar src={logo}
                  sx={{
                    height: 30,
                    width: 30,
                    objectFit: 'contain'
                  }}
                  alt='SpotPool' />
                <Typography variant="h5" component="div"
                  sx={{ flexGrow: 1, fontWeight: 600, color: 'silver', marginLeft: 1 }}>
                  SpotPool
                </Typography>
              </Link>

            </Toolbar>
          </AppBar>

        </StyledAppBar>
      </HideOnScroll>

      <Box sx={{ marginTop: { xs: 13, md: 20 }, marginX: { xs: 2, md: 10 } }} >
        {terms_and_conditions.map((section, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="h4" sx={{ fontSize: { xs: 30 }, color: 'black' }} gutterBottom>
              {section.title}
            </Typography>
            <Typography variant='body1' sx={{ textAlign: 'start', marginBottom: 2 }}>These terms and conditions govern your use of the SpotPool system and app provided by SpotPool technologies.
              By using SpotPool you agree to comply with and be bound by these terms.If you disagree with any part of these Terms,you may not use SpotPool.
            </Typography>
            {section.sections.map((subSection, subIndex) => (
              <div key={subIndex} style={{ textAlign: 'start', marginBottom: 25 }}>
                <Typography variant="h6" sx={{ color: 'black', textAlign: 'start' }} gutterBottom>
                  {subSection.subtitle}
                </Typography>
                {subSection.content}
              </div>
            ))}
          </div>
        ))}
      </Box>


    </Box >
  )
}

export default Terms