import { connect } from 'react-redux';
import storage from 'redux-persist/lib/storage'; // Use localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit'
import { appName } from '../configs';

const Connect = connect,
    initialState = {
        User: null,
        jwtToken: null,
        loggedIn: false,
        recent: [],
        contacts: [],
        mainContacts: [],
        myContacts: [],
        unViewedNotifications: false,
        instantPlay: false
    },
    reducer = (state = initialState, action: any) => {
        const { val, type } = action;
        if (type === 'multiplefields')
            return { ...state, ...val }
        return type === 'logOut' ? initialState : state;
    },
    mapStateToProps = (state: any) => state,
    mapDispatchToProps = (dispatch: any) => {
        return {
            setRedux: (val: any): void => dispatch({ type: 'multiplefields', val }),
            logOut: () => dispatch({ type: 'logOut' })
        };
    },
    persistConfig = {
        key: appName + 'authType',
        storage
    },

    pReducer = persistReducer(persistConfig, reducer),
    store = configureStore({
        reducer: pReducer, middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        })
    }),
    persistor = persistStore(store);
export {
    Connect,
    reducer,
    mapStateToProps,
    mapDispatchToProps,
    persistor,
    store
}