import React from 'react';
import Loader from '../Modals/loader';
import { useModal } from './index';

const DynamicModal: React.FC = () => {
    const { modal, info } = useModal();

    if (!modal) return null;

    const renderModal = () => {
        switch (modal) {
            case 'showLoader':
                return <Loader info={info!} />;
            default:
                return null;
        }
    };

    return <>{renderModal()}</>;
};

export default DynamicModal;
