import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#006B46',
      dark: '#030183',
    },
    secondary: {
      main: '#19857b',
    },
    text: {
      primary: '#333',
      secondary: '#fff',
    },
    // 'rgba(0, 107, 70, 0.1)'
    background: {
      default: '#fff',
      paper: '#ffffff'
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2rem',
    },
    body1: {
      color: 'grey',
    },
    body2: {
      color: '#959684',
    },

  },
  transitions: {
    duration: {
      shortest: 150,
    },
    easing: {
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
});

export default theme;
