
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import logo from '../../assets/images/pool.jpeg';
import { ArrowDropDown, VisibilityOff, RemoveRedEye } from '@mui/icons-material';

import { UserProps } from '../../types/user';
import { Api, showHideLoader } from '../../helpers'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux';
import { useNavigate } from 'react-router-dom';
import Show from '../common/show';
import ChangeSignInWith from './changeSignInWith';
import CodeVerification from '../common/codeVerification';

export interface inputProps {
    label?: string,
    value: string | boolean,
    error?: string | null,
    field?: string
    type?: string
    hidePass?: boolean
}

export type Page = 'Sign In' | 'Sign Up' | 'Forgot Password' | 'resetPass' | 'codeVerification'

const codeVer = 'codeVerification'

export type loginType = 'Phone' | 'Username'


interface StateProps {
    currentPage: Page,
    Username: inputProps,
    Password: inputProps,
    NewPass: inputProps,
    Phone: inputProps,
    ConfirmPass: inputProps,
    loginWith: loginType,
    previousPage: Page | null,
    showPass: boolean,
    country: {
        flag: string,
        code: string
    },
    forgotPinCode: string | undefined
    submitting: boolean
    openDialog: boolean
    instantPlay: boolean
}

interface Props {
    setRedux?: (data: any) => void
    User?: UserProps
    loggedIn?: boolean
}

export const frgtPass = 'Forgot Password'

const initialInputs = {
    Username: { label: 'Username', value: '', type: 'Username', field: 'Username' },
    Password: { label: 'Password', value: '', type: 'password', field: 'Password' },
    NewPass: { label: 'New Password', value: '', type: 'password', field: 'NewPass' },
    ConfirmPass: { label: 'Confirm Password', value: '', type: 'password', field: 'ConfirmPass' },
    Phone: { label: 'Phone', value: '', field: 'Phone' },
    AgreeToTerms: { label: 'Agree To Terms & Conditions', value: false, type: 'checkbox', field: 'AgreeToTerms' }
}



const Auth: FC<Props> = ({
    setRedux,
    User,
    loggedIn
}) => {
    const navigate = useNavigate();

    const [state, setState] = useState<StateProps>({
        currentPage: 'Sign In',
        ...initialInputs,
        showPass: false,
        previousPage: null,
        loginWith: 'Username',
        country: {
            flag: '🇰🇪',
            code: '254'
        },
        forgotPinCode: undefined,
        submitting: false,
        openDialog: false,
        instantPlay: true
    })

    const { openDialog, currentPage, showPass, country, submitting, forgotPinCode, previousPage, instantPlay } = state,
        loginWith = instantPlay ? 'Phone' : state.loginWith,

        inputs = instantPlay ? ['Phone'] : currentPage === 'Sign Up' ? ['Username', 'Phone', 'Password', 'AgreeToTerms'] : currentPage === 'Sign In' ? [loginWith === 'Username' ? 'Username' : 'Phone', 'Password'] : currentPage === 'resetPass' ? ['NewPass', 'ConfirmPass'] : [loginWith === 'Username' ? 'Username' : 'Phone']

    const componentIsMounted = useRef(true)

    const changeInput = (input: string, obj: any) => {
        let Input = { ...(state as any)[input] }
        Input = { ...Input, ...obj }
        setState({
            ...state,
            [input]: Input,
            submitting: false
        })
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const obj: any = {}

        data.forEach((value, key) => {
            obj[key] = key === 'Phone' ? '+' + parseInt(country.code) + parseInt(String(value)) : obj[key] = value
        })
        if (!(state as any).AgreeToTerms.value && currentPage === 'Sign Up')
            changeInput('AgreeToTerms', { error: 'You need to agree to the terms and conditions.' })
        else {
            setState({
                ...state,
                submitting: true
            })
            const datToSend = {
                controller: 'Users',
                action: instantPlay ? 'instantPlay' : currentPage,
                forgotPinCode,
                userId: User?._id,
                ...obj
            }
            Api(datToSend).then((resp: any) => {
                // showHideLoader(false)
                const { error, field, message, User } = resp
                if (componentIsMounted.current) {
                    if (error) {
                        changeInput(error.field, { error: error.message })
                        // emitEvent('toast', { message: error.message, type: 'error' });
                    }

                    else if (currentPage === 'resetPass') {
                        // EventsEmit('modal', { action: 'toastMsg', message, type: 'success' })
                        setState({
                            ...state,
                            currentPage: 'Sign In',
                            showPass: false,
                            previousPage: null,
                            ...initialInputs
                        })
                    }
                    else {
                        if (resp.loggedIn && setRedux) {
                            setRedux({ ...resp, User: resp.user || resp.User })
                            navigate('/home')
                        } else {
                            if (setRedux)
                                setRedux({ User })
                            setState({
                                ...state,
                                currentPage: 'codeVerification',
                                previousPage: currentPage,
                                ...initialInputs
                            })
                        }
                    }
                }


            }).catch((err) => {
                setState({
                    ...state,
                    submitting: false
                })
            })
        }
    }

    // useEffect(() => {
    //     // User && (console.log(User))
    // }, [])




    return (

        <Container component="main" maxWidth="xs" sx={{
            height: '100vh',
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            justifyContent: `center`,
            textAlign: 'center'
        }}>
            <CssBaseline />
            <Show>
                <Show.When isTrue={currentPage === codeVer}>
                    <CodeVerification
                        page={previousPage as Page}
                        loginWith={loginWith}
                        goBack={() => setState({
                            ...state,
                            currentPage: previousPage as Page
                        })
                        }
                        goToNextPage={(data: any) => setState({
                            ...state,
                            ...data,
                            previousPage: state.currentPage
                        })}
                        User={User}
                        setRedux={setRedux}
                    />

                </Show.When>
                <Show.Else>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{
                            mb: 5
                        }}>
                            <img src={logo} style={{
                                objectFit: 'cover',
                                height: 70,
                                width: 70,
                                borderRadius: '50%'
                            }} />

                            <Typography sx={{
                                //color: theme.lightTheme.palette.primary.main,
                                fontSize: 25,
                                fontWeight: 'bold',
                                color: 'primary.main'
                            }}>SpotPool</Typography>
                        </Box>
                        <Box component="form" sx={{ mt: 1, pb: 5 }} onSubmit={onSubmit}>
                            <Fragment>
                                <Show>
                                    <Show.When isTrue={!instantPlay && ['Sign In', 'Forgot Password'].includes(currentPage)}>
                                        <Typography
                                            onClick={() => setState({ ...state, openDialog: true })}
                                            sx={{
                                                display: 'flex',
                                                marginBottom: 2,
                                                color: 'primary.main',
                                                alignSelf: 'start',
                                                fontWeight: 'bold'
                                            }}>{currentPage === 'Sign In' ? 'Sign In with' : 'Check account by'}
                                            <span style={{
                                                display: 'flex',
                                                textDecoration: 'underline',
                                                alignItems: 'center',
                                                marginLeft: 4
                                            }}
                                            >{loginWith}<ArrowDropDown /></span> </Typography>
                                    </Show.When>
                                </Show>
                                <Grid container spacing={2}>
                                    {
                                        inputs.map((input, i: number) => {
                                            const { value, error, field, type, label } = (state as any)[input]
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={field}
                                                >
                                                    <Show>
                                                        <Show.When isTrue={type === 'checkbox'}>
                                                            <FormControlLabel
                                                                // sx={{ color: 'black' }}
                                                                control={<Checkbox
                                                                    value={value}
                                                                    color="primary"
                                                                    name={field}
                                                                    sx={{ color: 'grey' }}
                                                                    onChange={(e) => {
                                                                        changeInput(field, { error: null, value: e.target.checked })
                                                                    }}
                                                                />}
                                                                label={<Typography
                                                                    variant='body1' >I have read and agreed to the <a
                                                                        href='/terms_and_conditions'
                                                                        style={{ textDecoration: 'none' }}
                                                                    >Terms and Conditions</a> and <a
                                                                        href='policies'
                                                                        style={{ textDecoration: 'none' }}
                                                                    >Privacy Policies</a></Typography>}

                                                            />
                                                            <Show>
                                                                <Show.When isTrue={Boolean(error)}>
                                                                    <Typography color={'error'} sx={{ fontSize: 14 }}>*{error}</Typography>
                                                                </Show.When>
                                                            </Show>
                                                        </Show.When>
                                                        <Show.Else>
                                                            <TextField
                                                                variant='standard'
                                                                autoComplete="given-name"
                                                                name={field}
                                                                required
                                                                fullWidth
                                                                label={label}
                                                                type={type === 'password' ? (showPass ? 'text' : type) : type}
                                                                autoFocus
                                                                error={Boolean(error)}
                                                                helperText={error}
                                                                onFocus={() => changeInput(field, { error: null })}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        input === 'Phone' && (
                                                                            <InputAdornment position="start">
                                                                                <PhoneInput
                                                                                    country={'ke'}
                                                                                    value={state.country.code}
                                                                                    onChange={(value, country: any) => setState({
                                                                                        ...state,
                                                                                        country: {
                                                                                            flag: country.countryCodeEmoji,
                                                                                            code: country.dialCode
                                                                                        }
                                                                                    })}

                                                                                    inputStyle={{ border: 'none', outline: 'none', width: 85, backgroundColor: 'transparent' }}
                                                                                    dropdownStyle={{ top: 'auto', bottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'start', width: 380 }}
                                                                                    buttonStyle={{
                                                                                        backgroundColor: 'transparent', border: 'none', transition: 'none',

                                                                                    }}

                                                                                />



                                                                            </InputAdornment>)
                                                                    ),

                                                                    endAdornment:
                                                                        (
                                                                            ['Password', 'ConfirmPass', 'NewPass'].includes(field) ?
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        onClick={() => setState({
                                                                                            ...state,
                                                                                            showPass: !showPass
                                                                                        })}
                                                                                    >
                                                                                        {
                                                                                            showPass ?
                                                                                                <VisibilityOff /> :
                                                                                                <RemoveRedEye />
                                                                                        }
                                                                                    </IconButton>
                                                                                </InputAdornment> :
                                                                                <Fragment />
                                                                        )
                                                                }}

                                                                sx={{ backgroundColor: 'transparent' }}

                                                            />
                                                        </Show.Else>
                                                    </Show>
                                                    <Show>
                                                        <Show.When isTrue={currentPage === 'Sign In' && input === 'Password' && !instantPlay}>
                                                            <Box sx={{
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                mt: 2
                                                            }}
                                                            >
                                                                <Button
                                                                    sx={{
                                                                        textTransform: 'none',
                                                                        textDecoration: 'underline'
                                                                    }}
                                                                    onClick={() => setState({ ...state, currentPage: 'Forgot Password' })}
                                                                >Forgot Password?</Button>
                                                            </Box>
                                                        </Show.When>
                                                    </Show>
                                                </Grid>
                                            )
                                        }

                                        )
                                    }
                                </Grid>
                                <FormControlLabel
                                    sx={{ mt: 3, mb: 3 }}
                                    control={<Checkbox
                                        checked={instantPlay}
                                        color="primary"
                                        name={'playWithoutAccount'}
                                        sx={{ color: 'grey' }}
                                        onChange={(e) => {
                                            setState({ ...state, instantPlay: e.target.checked })
                                        }}
                                    />}
                                    label={<Typography variant='body1'>Continue without creating account </Typography>}
                                />
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, textTransform: 'none', borderRadius: 30 }}
                                    loading={submitting}

                                >
                                    {instantPlay ? 'Continue' : currentPage === 'Forgot Password' ? 'Next' : currentPage}
                                </LoadingButton>

                                <Show>
                                    <Show.When isTrue={!instantPlay}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <Link
                                                    sx={{
                                                        cursor: 'pointer',
                                                        textAlign: 'center',
                                                        textDecoration: 'none',
                                                    }}
                                                    onClick={() => setState({
                                                        ...state,
                                                        currentPage: currentPage === 'Sign In' ? 'Sign Up' : 'Sign In',
                                                        ...initialInputs,
                                                        showPass: false
                                                    })}
                                                    variant="body2"
                                                >
                                                    <Box >
                                                        <span style={{
                                                            color: 'black',
                                                            textDecoration: 'none'
                                                        }}>{currentPage === 'Sign In' ? 'Don\'t have an account?' : currentPage === 'Sign Up' ? 'Already have an account?' : 'Back to Login'} </span>
                                                        <Show>
                                                            <Show.When isTrue={['Sign In', 'Sign Up'].includes(currentPage)}>
                                                                <span style={{ marginLeft: '3px', textDecoration: 'underline' }}>{currentPage === 'Sign In' ? 'Sign Up' : 'Sign In'}</span>
                                                            </Show.When>
                                                        </Show>
                                                    </Box>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Show.When>
                                </Show>


                            </Fragment>
                        </Box>
                    </Box>
                    <Show>
                        <Show.When isTrue={openDialog}>
                            <ChangeSignInWith
                                close={() => setState({ ...state, openDialog: false })}
                                onOptionSelect={(loginWith: 'Username' | 'Phone') => setState({
                                    ...state,
                                    openDialog: false,
                                    loginWith
                                })}
                            />
                        </Show.When>
                    </Show>
                </Show.Else>
            </Show>



        </Container >
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(Auth);
