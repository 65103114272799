import React, { FC, Fragment, useEffect } from 'react';
// import Navbar from '../LandingSections/Navbar';
import Hero from '../LandingSections/Hero';
import About from '../LandingSections/About';
import Pricing from '../LandingSections/Pricing';
import Contact from '../LandingSections/Contact';
import Faqs from '../LandingSections/Faqs';
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from '../LandingSections/Footer';
import { Connect, mapStateToProps } from '../Redux';
import { useNavigate } from 'react-router-dom';

interface Props {
    loggedIn?: boolean
}

const Layout: FC<Props> = ({
    loggedIn
}) => {
    const navigate = useNavigate()

    useEffect(() => {
        Aos.init({
            duration: 1800,
            offset: 100,
            //disable: "mobile",
        });
        if (loggedIn)
            navigate('/home')
        else
            Aos.init({
                duration: 1800,
                offset: 100,
            });
        return () => {

        }
    }, []);

    return (
        <Fragment>
            <Hero />
            <About />
            <Pricing />
            <Faqs />
            <Contact />
            <Footer />
        </Fragment>

    )
}

export default Connect(mapStateToProps)(Layout);
