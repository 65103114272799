
import React, { FC, useEffect, useState } from 'react';
import { Modal, Box, Typography, CircularProgress, useTheme } from '@mui/material';

interface ModalProps {
    // close?: () => void;
    info: {
        text: string;
    };
}

const Loader: FC<ModalProps> = ({ info }) => {
    const { text } = info;
    const [loaderInfo, setLoaderInfo] = useState<{ text: string } | null>(null);



    return (
        <Modal

            open={Boolean(loaderInfo)}
            onClose={() => setLoaderInfo(null)}
            aria-labelledby="loading-modal"
            aria-describedby="loading-indicator"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(3px)',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: 24
                }}
            >
                <CircularProgress size={30} sx={{ color: 'blue' }} />
                <Typography variant="body1" sx={{ marginTop: '10px', color: 'blue' }}>
                    {text}
                </Typography>
            </Box>
        </Modal>
    );
};

export default Loader;

