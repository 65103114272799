import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { AlertProps } from '.';
import { DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Api } from '../../helpers';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface Props {
    close: () => void
    notification: AlertProps
}
const NotificationModal: React.FC<Props> = ({
    close,
    notification
}) => {
    const readNotification = () => {
        Api({
            controller: 'Notifications',
            action: 'readNotification',
            notificationId: notification._id
        }).then(() => {

        }).catch((err) => {
            console.log(err)
        })
    }

    React.useEffect(() => {
        readNotification()
        return () => {

        }
    }, [])


    return (
        <Dialog
            fullScreen
            open
            onClose={close}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', bgcolor: '#fff' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={close}
                        aria-label="close"
                        sx={{
                            color: '#000'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, color: '#000' }} variant="h6" component="div">
                        Notification
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogTitle sx={{ color: '#000', fontWeight: 'bold' }}>{notification.title}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{
                    color: '#000',
                    mt: 5,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    whiteSpace: 'pre-wrap',
                }}>{notification.message}</DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
export default NotificationModal