import React from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useNavigate } from 'react-router-dom';

interface DepositProps {
    // openCheckdeposit: () => void;
    // depositsBack: () => void;
}


const Deposits: React.FC<DepositProps> = () => {

    const deposits = [
        {
            date: '2024-06-17',
            phoneNumber: '+1234567890',
            status: 'completed',
            time: '10:00 AM',
            amount: 100000,
        },
        {
            date: '2024-06-16',
            phoneNumber: '+1987654321',
            status: 'pending',
            time: '2:30 PM',
            amount: 50,
        },
        {
            date: '2024-06-15',
            phoneNumber: '+1555090909',
            status: 'cancelled',
            time: '4:45 PM',
            amount: 75,
        },
        {
            date: '2024-06-14',
            phoneNumber: '+1444555666',
            status: 'completed',
            time: '9:15 AM',
            amount: 120,
        },
        {
            date: '2024-06-13',
            phoneNumber: '+1777888999',
            status: 'pending',
            time: '11:20 AM',
            amount: 80,
        },
        {
            date: '2024-06-12',
            phoneNumber: '+1888777666',
            status: 'completed',
            time: '3:00 PM',
            amount: 200,
        },
        {
            date: '2024-06-11',
            phoneNumber: '+1666777888',
            status: 'pending',
            time: '1:45 PM',
            amount: 150,
        },
        {
            date: '2024-06-10',
            phoneNumber: '+1999888777',
            status: 'cancelled',
            time: '10:30 AM',
            amount: 90,
        },
        {
            date: '2024-06-09',
            phoneNumber: '+1222333444',
            status: 'completed',
            time: '5:20 PM',
            amount: 180,
        },
        {
            date: '2024-06-08',
            phoneNumber: '+1888333222',
            status: 'pending',
            time: '12:00 PM',
            amount: 60,
        },
    ];

    const navigate = useNavigate()

    return (
        <Box sx={{ marginX: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'start', gap: 15, position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1000, paddingY: 2, marginBottom: 1 }}>
                <IconButton onClick={() => navigate(-1)}>
                    < ArrowBackOutlinedIcon />
                </IconButton>

                <Typography variant='h5'>Deposits</Typography>

            </Box>


            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                {
                    deposits.map((deposit, i) => {
                        const { date, phoneNumber, status, time, amount } = deposit
                        return (
                            <Grid
                                item
                                xs={12}
                                key={i}
                                sx={{ display: 'flex', paddingY: 2, alignItems: 'center', }}
                                onClick={() => navigate('/checkdeposit')}>
                                <AccountBalanceWalletOutlinedIcon />
                                <Box sx={{ borderBottom: '1px solid silver', width: '100%', marginLeft: 2, display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: 'black', gap: 1, }}>
                                        <Typography variant='h6' sx={{ fontWeight: { xs: 'bold' }, fontSize: { xs: 18, sm: 20 } }}>{date}</Typography>
                                        <Typography variant='body2'>{phoneNumber}</Typography>
                                        <Typography variant='body2'>{status}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                                        <Typography variant='body2' sx={{ alignSelf: 'flex-end' }}>{time}</Typography>
                                        <Typography variant='h6' sx={{ alignSelf: 'flex-end', fontSize: { xs: 16, sm: 19 } }}>KES {amount}</Typography>
                                    </Box>
                                </Box>

                            </Grid>

                        )
                    })
                }

            </Grid >

        </Box>
    )
}

export default Deposits