import { Brush, Logout } from '@mui/icons-material'
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material'
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux'
import { UserProps } from '../../types/user'
import { FC, useEffect } from 'react'
import { getFormattedPhone, numberWithCommas } from '../../helpers'
import { useNavigate } from 'react-router-dom';
import eventBus from '../../configs/eventsBus'
import avatar from '../../assets/images/avatar.jpg'
import Show from '../common/show'
interface Props {
    User?: UserProps
    logOut?: () => void
    instantPlay?: boolean
}

const Profile: FC<Props> = ({
    User,
    instantPlay,
    logOut
}) => {
    const navigate = useNavigate()
    const Btns = [
        { icon: <Brush />, onPress: () => { } },
        {
            icon: <Logout />, onPress: () => {
                if (logOut)
                    logOut()
                navigate('/')
            }
        }
    ]
    useEffect(() => {
        eventBus.dispatch('routes', { action: 'getUserData' })

        return () => {
        }
    }, [])

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 2
            }}>
                <Typography sx={{
                    color: '#000',
                    fontSize: 25,
                    fontWeight: 'bold'
                }}>Profile</Typography>
                <Box>
                    {
                        Btns.map(({ icon, onPress }, i) => (
                            <IconButton
                                onClick={onPress}
                                key={i}
                            >
                                {icon}
                            </IconButton>
                        ))
                    }
                </Box>
            </Box>
            <Box sx={{
                padding: 2,
                pt: 3,
                display: 'flex',
                flexDirection: 'row'
            }}>

                <img style={{
                    display: 'flex',
                    alignSelf: 'start',
                    borderRadius: 15,
                    height: 120,
                    width: 120,
                }} src={User?.Image || avatar} alt='' />

                <Box sx={{
                    ml: 5
                }}>
                    <Typography sx={{
                        fontWeight: 'bold',
                        fontSize: 16,
                        color: '#000'
                    }}>{User?.Username}</Typography>
                    <Typography sx={{

                    }}>{getFormattedPhone(String(User?.Phone))}</Typography>
                    <Show>
                        <Show.When isTrue={instantPlay}>
                            <Typography sx={{ color: 'orange', fontWeight: 'bold' }}>Instant Play Mode</Typography>
                        </Show.When>
                    </Show>

                    <Typography sx={{ mt: 3 }}>Token Balance</Typography>
                    <Typography sx={{
                        color: '#000',
                        fontSize: 20
                    }}>KES {numberWithCommas(instantPlay ? 0 : String(User?.balance))}</Typography>
                    <Show>
                        <Show.When isTrue={!instantPlay}>
                            <Box>
                                <Button
                                    sx={{
                                        color: '#fff',
                                        bgcolor: '#000',
                                        borderRadius: 10,
                                        mt: 1,
                                        pl: 3,
                                        pr: 3,
                                        height: 30
                                    }}
                                    onClick={() => navigate("/topup")}
                                >Top Up</Button>
                            </Box>
                        </Show.When>
                    </Show>
                </Box>
            </Box>
        </Box>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(Profile)