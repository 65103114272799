import { Cancel, QrCodeScanner, Search as SearchIcon } from "@mui/icons-material"
import { Typography, Box, IconButton, InputBase, List, ListItemButton, Avatar } from "@mui/material"
import { styled } from '@mui/material/styles';
import { PlacesProps } from "../../types";
import { FC, useCallback, useEffect, useState } from "react";
import EmptyView from "../common/EmptyView";
import Show from "../common/show";
import { Api, getAbbr, numberWithCommas } from "../../helpers";
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Connect, mapDispatchToProps, mapStateToProps } from "../../Redux";
import QrScanner from "./qrScan";


interface StateProps {
    places: PlacesProps[]
    loading: boolean
    searchVal: string | null
    searching: boolean
    refreshing: boolean
    showQr: boolean
}
interface Props {
    page?: 'Home',
    loggedIn?: boolean
    setRedux?: (data: any) => void
}

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(236, 236, 236, 1)',
    '&:hover': {
        backgroundColor: 'rgba(236, 236, 236, 2)',
    },
    marginLeft: 0,
    margin: 5,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


export const infoStyles = {
    marginTop: 4,
    fontSize: 16,
    display: 'flex',
    color: '#000'
}
export const labelStyles = {
    color: '#727A8A'
}
const Home: FC<Props> = ({
    page,
    loggedIn,
    setRedux
}) => {
    const [state, setState] = useState<StateProps>({
        places: [],
        loading: true,
        searching: false,
        searchVal: null,
        refreshing: false,
        showQr: false
    })
    const { places, loading, searching, searchVal, refreshing, showQr } = state
    const initiateScan = () => {
        setState({ ...state, showQr: true })
    }
    const getMyPools = ({
        searchVal,
        loadingProp
    }: any) => {
        if (loadingProp)
            setState({ ...state, [loadingProp]: true })
        const afterProps = {
            searching: false,
            loading: false,
            searchVal,
            refreshing: false
        }
        Api({
            controller: 'Users',
            action: searchVal ? 'searchAllPlaces' : 'getPlayerPools',
            searchVal
        }).then((resp: any) => {
            setState({
                ...state,
                places: resp,
                ...afterProps
            })
        }).catch(() => {
            setState({
                ...state,
                ...afterProps
            })
        })
    },
        navigate = useNavigate(),

        searchPlace = (searchVal: string) => getMyPools({ searchVal, loadingProp: 'searching' }),
        startSearch = useCallback(_.debounce(searchPlace, 1000), [])
    const onScan = (url: string) => {
        if (url) {
            Api({
                controller: 'Users',
                action: 'checkForPlace',
                qrValue: url,
            }).then((resp: any) => {
                const { error } = resp
                if (error)
                    setTimeout(() => {
                        // EventsEmit('modal', {
                        //     modal: 'showAlert',
                        //     info: {
                        //         title: 'Invalid QR',
                        //         message: error,
                        //         buttons: [
                        //             {
                        //                 text: 'I understand',
                        //                 backgroundColor: colors.main,
                        //                 color: '#fff'
                        //             }
                        //         ]
                        //     }
                        // })
                    })
                else {
                    setState({
                        ...state,
                        showQr: false
                    })
                    navigate('/tables', {
                        state: {
                            _id: resp.placeId,
                            isCustomer: true,
                            pricePerGame: resp.placeTokenPrice,
                            scanned: true
                        }
                    })
                }
            }).catch((err: any) => {
                console.log(err)
                // showHideLoader(false)
                alert(err)
            })

        }

    }
    const checkForNotifications = () => {
        Api({
            controller: 'Users',
            action: 'checkForNotifications'
        }).then((resp) => {
            if (setRedux)
                setRedux({ unViewedNotifications: resp })
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        if (!loggedIn && '1' === searchVal)
            navigate('/')
        else {
            getMyPools({})
            checkForNotifications()
            // const eventListner = Events.addEventListener('mypools', (data: any) => {
            //     switch (data.action) {
            //         case 'getMyPools':
            //             getMyPools({})
            //             break;
            //     }
            // })

            return () => {
                // Events.removeEventListener(eventListner as string)
            }
        }
    }, [])
    return (
        <Box  >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 2
            }}>
                <Typography sx={{
                    color: '#000',
                    fontSize: 25,
                    fontWeight: 'bold'
                }}>SpotPool</Typography>
                <IconButton
                    onClick={initiateScan}
                >
                    <QrCodeScanner />
                </IconButton>
            </Box>
            <Search sx={{
                borderRadius: 3
            }}>
                <SearchIconWrapper>
                    {/* <IconButton
                    onClick={()=>setState({...state, searchVal:''})}
                    >
                        <Cancel sx={{fontSize:20, color:'#A1A1A1'}} />
                    </IconButton> */}
                    <SearchIcon sx={{ fontSize: 20, color: 'black' }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder='Search'
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => startSearch(e.target.value)}
                />
            </Search>
            <Show>
                <Show.When isTrue={loading || searchVal && !places.length}>
                    <EmptyView
                        loading={loading}
                        oopTxt={page ? undefined : 'Oops!'}
                        text={page ? 'Scan to add a pool or search by place name.' : 'You have not added any pools'}
                        button={{
                            text: page ? 'Scan' : 'Add Pool',
                            onPress: initiateScan
                        }}
                        searchValue={searchVal}
                    />
                </Show.When>
                <Show.Else>
                    <List>
                        {
                            places.map((item) => {
                                const { placeName, logo, _id, numberOfTables, pricePerGame, balance } = item
                                return (
                                    <ListItemButton key={_id}
                                        sx={{
                                            borderBottom: '1px solid #C7C7C7',
                                            mt: 1,
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}
                                        onClick={() => {
                                            navigate('/tables', {
                                                state: item
                                            })
                                        }}
                                    >
                                        <Show>
                                            <Show.When isTrue={Boolean(logo)}>
                                                <img src={logo} style={{
                                                    height: 70,
                                                    width: 70,
                                                    borderRadius: 10
                                                }} />
                                            </Show.When>
                                            <Show.Else  >
                                                <Avatar alt={getAbbr(placeName)} sx={{ height: 70, width: 70 }}  >{getAbbr(placeName)}</Avatar>
                                            </Show.Else>
                                        </Show>
                                        <Box style={{ flex: 1, marginLeft: 10 }}>
                                            <Typography style={{
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                maxWidth: '80%',
                                                marginBottom: 5,
                                                color: '#000'
                                            }}>{placeName}</Typography>
                                            <Box style={infoStyles}>
                                                <Typography sx={labelStyles}>Number of tables:</Typography> {numberOfTables}
                                            </Box>
                                            <Box style={infoStyles}><Typography sx={labelStyles}>Token Price (Kes):</Typography> {numberWithCommas(pricePerGame)} </Box>
                                            {/* <Show>
                                            <Show.When isTrue={!Boolean(page)}>
                                                <Box style={infoStyles}><Typography style={labelStyles}>Balance (Kes):</Typography> {numberWithCommas(balance || 0)} </Box>
                                            </Show.When>
                                        </Show> */}
                                        </Box>
                                    </ListItemButton>
                                )
                            })
                        }
                    </List>
                </Show.Else>
            </Show>
            <Show>
                <Show.When isTrue={showQr}>
                    <QrScanner
                        onClose={() => setState({ ...state, showQr: false })}
                        onScan={onScan}
                    />
                </Show.When>
            </Show>
        </Box>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(Home)