import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import eventBus from '../../configs/eventsBus';

interface StateProps {
    btns: any[]
    open: boolean
    title: string | null
    message: string | null
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const [state, setState] = React.useState<StateProps>({
        btns: [],
        open: false,
        title: null,
        message: null
    });
    const close = () => setState({ ...state, open: false })
    const { btns, open, title, message } = state
    const navigate = useNavigate();



    React.useEffect(() => {

        eventBus.on('showAlert', (info: any) => {
            let { action, title, message, buttons } = info.detail
            if (action === 'close')
                close()
            else {
                setState({
                    ...state,
                    title,
                    open: true,
                    message,
                    btns: buttons
                })
            }
        })
        return () => {

        }
    }, [])
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#000' }} id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        btns.map((q, i) => (
                            <Button
                                variant={'contained'}
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: q.backgroundColor,
                                    color: q.color
                                }} key={i}
                                onClick={() => {
                                    if (q.goBack || q.page)
                                        navigate(q.page || -1)
                                    close()
                                }}
                            >{q.text}</Button>
                        ))
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
