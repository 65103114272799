
import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Keyboard from './Keyboard';
import { TextField, Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Api, showHideLoader } from '../../helpers';
import { Connect, mapStateToProps } from '../../Redux';
import { UserProps } from '../../types/user';
import Show from '../common/show';
import eventBus from '../../configs/eventsBus';


interface StateProps {
    value: string,
    error: string | null,
}
interface Props {
    User?: UserProps
}
const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    alignSelf: 'center',
    marginBottom: 5
}));

const CurrencyTypography = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(-4),

}));

const Topup: React.FC<Props> = ({
    User
}) => {
    const [state, setState] = useState<StateProps>({
        value: '',
        error: null
    })
    const { value, error } = state
    const navigate = useNavigate();
    const onChangeText = (key: string) => {
        if (key === 'close') {
            var str = value.substring(0, value.length - 1)
            setState({
                ...state,
                value: str,
                error: null
            })
        }
        else {
            var newValue = value + '' + key;
            if (newValue.length <= 10)
                setState({
                    ...state,
                    value: newValue,
                    error: null
                })
        }
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.blur();
    };
    const onSubmit = () => {
        if (!value /*|| parseInt(value) < 20*/)
            setState({
                ...state,
                error: 'Amount should be at least KES 20.00'
            })
        else {
            showHideLoader(true, 'Processing...')
            Api({
                controller: 'Payments',
                action: 'depositFunds',
                value,
                account: User?.Username
            }).then(({ success, message, title }: any) => {
                showHideLoader(false)
                if (success) {
                    eventBus.dispatch('routes', { action: 'toastMsg', message, type: 'success' })
                    navigate(-1)
                    eventBus.dispatch('routes', { action: 'getUserData' })
                }
                if (title)
                    eventBus.dispatch('showAlert', {
                        title,
                        message,
                        buttons: [
                            {
                                text: 'I understand',
                                color: '#fff',
                                goBack: true
                            }
                        ]
                    })
                else
                    setState({ ...state, error: 'An error occured. Please try again.' })

            }).catch((err: any) => {
                showHideLoader(false)
                setState({ ...state, error: 'An error occured. Please try again.' })
            })
        }
    }

    return (
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 14 }}>
                <IconButton onClick={() => navigate(-1)} >
                    <ArrowBackIosIcon />
                </IconButton>

                <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Top Up</Typography>
                <IconButton onClick={() => navigate("/deposits")}>
                    <ScheduleIcon />
                </IconButton>

            </Box>
            <Typography variant="body1" sx={{ marginTop: 5, marginBottom: 10 }}>Enter amount</Typography>
            <StyledBox>
                <CurrencyTypography sx={{ color: 'black', marginTop: 3 }} >{'KES'}</CurrencyTypography>
                <TextField
                    value={value as string}
                    onFocus={handleFocus}
                    placeholder={'0.00'}
                    InputProps={{
                        style: { fontSize: value.length > 5 ? 20 : 50 },
                        disableUnderline: true
                    }}
                    sx={{
                        width: '200px',
                        height: '80px',
                        textAlign: 'center',
                        '& .MuiInputBase-input': {
                            textAlign: 'center',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                />
            </StyledBox>
            <Show>
                <Show.When isTrue={Boolean(error)}>
                    <Typography sx={{ color: 'red' }}>*{error}</Typography>
                </Show.When>
            </Show>
            <Keyboard
                onChangeText={onChangeText}
                value={value as string}
                noMarginBottom={true} all={false} pin={false} disableAll={false} biometric={false}
                onSubmit={onSubmit}
            />
        </Box>
    )
}

export default Connect(mapStateToProps)(Topup)