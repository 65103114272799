import React, { FC, useRef, useState } from 'react'
import Header from './header'
import { Box, TextField, Typography } from '@mui/material'
import Counter from './counter'
import { Page, frgtPass, loginType } from '../Auth';
import { Api, showHideLoader } from '../../helpers';
import { useNavigate } from 'react-router-dom';

interface Props {
    goBack: () => void
    User?: any
    verifyPage?: string
    setRedux?: (dta: any) => void
    loginWith: loginType
    page?: Page
    goToNextPage?: (data: any) => void
    VerifyCode?: (code: string) => void
    Value?: string
}

interface StateProps {
    loading: boolean,
    value: string,
    error: string | null
}

const CodeVerification: FC<Props> = ({
    goBack,
    setRedux,
    User,
    verifyPage,
    loginWith,
    page,
    goToNextPage,
    VerifyCode,
    Value
}) => {

    const [state, setState] = useState<StateProps>({
        value: '',
        loading: false,
        error: null,
    })
    const { value, error } = state
    const componentIsMounted = useRef(true)


    const verify = (code: string) => {
        showHideLoader(true, 'Verifying...')
        // console.log(User)

        Api({
            controller: 'Users',
            action: verifyPage || 'verifyCode',
            code,
            userId: User._id,
            loginWith,
            page,
            updateContact: Boolean(Value)
        }).then((resp: any) => {
            const { error, message, jwtToken } = resp
            if (error && componentIsMounted.current)
                setState({
                    ...state,
                    error: message,
                    value: code
                })
            else if (page === frgtPass && goToNextPage)
                goToNextPage({ forgotPinCode: code, currentPage: 'resetPass' })
            else if (VerifyCode)
                VerifyCode(code)
            else if (setRedux) {
                setRedux({
                    jwtToken,
                    loggedIn: true
                })
            }
            showHideLoader(false)
        }).catch((e: any) => showHideLoader(false))

    }
    return (
        <>
            <Header title={'Verification'} goBack={goBack} />
            <Typography sx={{ color: '#000', mb: 3 }}>Enter verification code sent to your phone</Typography>
            <Counter
                loginWith={loginWith}
                setError={(error: string | null) => setState({ ...state, error })}
                Value={Value}
                User={User}
            />

            <Box sx={{ mt: 16 }}>
                {error ? (<Typography sx={{
                    fontSize: 15,
                    color: '#F61E1E',
                    marginBottom: 3,
                    textAlign: 'center'
                }
                }>
                    {error}
                </Typography>) : null}

            </Box>
            <TextField
                variant='standard'
                placeholder='Enter code'
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = event.target.value;
                    if (newValue.length <= 4) {
                        setState({
                            ...state,
                            value: newValue,
                            error: null
                        })
                        if (newValue.length === 4) {
                            verify(newValue);
                        }
                    }
                }}
            />

        </>

    )
}

export default CodeVerification;