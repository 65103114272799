import React, { useState } from 'react';
import Profile from '../Profile';
import Home from '../Home';
import { BottomNavigation, BottomNavigationAction, Box, Button, CssBaseline, List, Paper } from '@mui/material';
import { Person, House, HouseOutlined, PersonOutlined, NotificationsOutlined, Notifications as NotificationsIcon, Circle } from '@mui/icons-material';
import Notifications from '../Notifications';
import Show from '../common/show';
import { Connect, mapStateToProps } from '../../Redux';

interface StateProps {
    currentScreen: 'Home' | 'Profile'
}
interface Props{
    unViewedNotifications?:boolean
}

const Screens: React.FC<Props> = ({
    unViewedNotifications
}) => {
    const [state, setState] = useState<StateProps>({
        currentScreen: 'Home'
    })
    const { currentScreen } = state
    const iconStyles = { color: 'primary.dark' }
    const Badge = () => <Show>
        <Show.When isTrue={unViewedNotifications}>
            <Circle sx={{ height: 10, width: 10, color: 'red' }} />
        </Show.When>
    </Show>
    const tabs = [
        {
            label: 'Home',
            icon: <House sx={iconStyles} />,
            iconOutlined: <HouseOutlined sx={iconStyles} />,
            page: <Home />
        },
        {
            label: 'Notifications',
            icon: <Box>
                <NotificationsIcon sx={iconStyles} />
                <Badge />
            </Box>,
            iconOutlined: <Box><NotificationsOutlined sx={iconStyles} /> <Badge /></Box>,
            page: <Notifications />
        },
        {
            label: 'Profile',
            icon: <Person sx={iconStyles} />,
            iconOutlined: <PersonOutlined sx={iconStyles} />,
            page: <Profile />
        }
        
    ]

    return (

        <Box sx={{ pb: 7, bgcolor: 'white' }}  >
            <CssBaseline />
            {tabs.find(q => q.label === currentScreen)?.page}
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    sx={{ bgcolor: '#ECECEC' }}
                >
                    {
                        tabs.map(({ icon, iconOutlined, label }) => (
                            <BottomNavigationAction
                                key={label}
                                label={label}
                                icon={currentScreen === label ? icon : iconOutlined}
                                onClick={() => setState({ ...state, currentScreen: label as 'Home' | 'Profile' })}
                                sx={{
                                    '& .MuiBottomNavigationAction-label': {
                                        color: 'primary.dark',
                                    },
                                }}
                            />
                        ))
                    }
                </BottomNavigation>
            </Paper>
        </Box>

    );
};

export default Connect(mapStateToProps)(Screens);
