import { AccountCircle, Cancel, PhoneAndroid } from "@mui/icons-material";
import { Box, Button, Dialog, IconButton, List, ListItemButton, Slide, Toolbar, Typography } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import React, { FC } from "react"


interface Props {
    close: () => void
    onOptionSelect: (option: 'Username' | 'Phone') => void
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const signInOptions = [
    {
        icon: <AccountCircle />,
        label: 'Username'
    },
    {
        icon: <PhoneAndroid />,
        label: 'Phone'
    }
];
const ChangeSignInWith: FC<Props> = ({
    close,
    onOptionSelect
}) => {
    return (
        <Dialog
            open
            onClose={close}
            TransitionComponent={Transition}
            fullWidth
            PaperProps={{
                sx: {
                    position: 'fixed',
                    bottom: 0,
                    m: 0,
                    width: '100%',
                    maxHeight: '120vh',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.01)',
                    borderRadius: '25px 25px 0 0'
                },
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(0,0,0,0.3)',
                },
            }}
            sx={{
                zIndex: 1300,
            }}
        >
            <Toolbar sx={{ marginBottom: 2 }}>
                <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold', fontSize: 17 }} variant="h6" component="div">
                    Sign In With
                </Typography>
                <IconButton  onClick={close}>
                    <Cancel />
                </IconButton>
            </Toolbar>
            <List sx={{ ml: 2, mb: 2 }}>
                {signInOptions.map((option, index) => (
                    <ListItemButton
                        key={index}
                        sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}
                        onClick={() => onOptionSelect(option.label as 'Username' | 'Phone')}
                    >
                        {option.icon}
                        <Typography variant="body1" ml={1}>
                            {option.label}
                        </Typography>
                    </ListItemButton>
                ))}
            </List>
        </Dialog>
    )
}

export default ChangeSignInWith