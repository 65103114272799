import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

interface CheckDepositProps {
  // checkdepositBack: () => void;
}
const CheckDeposit: React.FC<CheckDepositProps> = () => {

  const navigate = useNavigate()
  return (
    <Box sx={{ marginX: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'start', gap: 9, position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1000, paddingY: 2, marginBottom: 1 }}>
        <IconButton onClick={() => navigate(-1)}>
          < ArrowBackOutlinedIcon />
        </IconButton>

        <Typography variant='h5'>Check Deposit</Typography>
      </Box>


      <Box sx={{ marginTop: 30 }}>
        <TextField
          variant='standard'
          autoComplete="given-name"
          // name={field}
          // required
          fullWidth
          label='Mpesa Receipt Number'
          type='text'
          autoFocus
          // error={Boolean(error)}
          // helperText={error}
          // onFocus={() => changeInput(field, { error: null })}


          sx={{ backgroundColor: 'transparent' }}

        />

        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ mt: 4, textTransform: 'none', borderRadius: 30, paddingX: 5 }}
        // loading={submitting}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>


  )
};

export default CheckDeposit;
