const isProduction = true

export const env = isProduction ? 'production' : 'development',
    // terms_url = 'https://vendor.mobi/terms_conditions.html',
    port = '6060',
    appName = 'SpotPool',

    ip = isProduction ? 'spotpool.co' : ('192.168.100.13:' + port),
    url = `http${isProduction ? 's' : ''}://${ip}`,
    urlwebSocket = `ws${isProduction ? 's' : ''}://${ip}`,
    public_encryption_key = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArI8gERjQ1DC+cZsJW2kL
jDddhEozWqHRbJNJ9UBRSd5Om0slNfEjNgYAlaqEtIZN5ilNQ4EX0gP3Myedr0TX
6+I9xy1H/JZradCLo2Lha48H+nIzKXVE2yM5a4NN6AD0pYCIfrgoejZXepTBRGWX
tA/5fH4ljp84rnJ66TprF7Odt/TnWchojZGOY8l6C+QAlO2UE64ve0J7h48MbMgv
YjnQi5gR3HdvVQBs+2CC5xnC7/p4smtb0BRnGEktdAFl/gHun166jPc1QjauIIxt
q1tu+DmUEhdBIvmdA4L6Ind1xTKsxDwKO6tc0ie1V/l14oCyIXkhJAsW74Q05jBW
n4a993UFTbNz2AgELTY2ijN/VMeaUw+agM+AQspT6v1TuSp3pWvE1Mrz2j8f4jdA
Fai+sNQZdmXyxwP/DwGPi4a1VarNLHZhs+f3nlYJZaFhGiAnc2eKSG77nvYq2v4X
iZak/lSkfKNw9QxnfCWHWI4A8lG5I54j5RxYA1tsad+oo17OKyWlYkQ+HjWVRFBh
ptcy4wjNNQpOs3os0aFOKfeVg2EDLYgbZUA8KqriBKuK8ziGO6PUz52bDebpnzWn
KIiFztf7+bp4PiIine+WkadzJj+0Ti45gQiuU9s3uRlq3rD5QwWomgT33V+zIZus
ERYb+i8f+jyxvXJk68FEqhcCAwEAAQ==
-----END PUBLIC KEY-----`
