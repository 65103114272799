import { Avatar, Box, Button, IconButton, List, Typography } from "@mui/material"
import { Connect, mapDispatchToProps, mapStateToProps } from "../../Redux"
import { ArrowBackIos, Search as SearchIcon } from "@mui/icons-material"
import { useLocation, useNavigate } from 'react-router-dom';
import { Search, SearchIconWrapper, StyledInputBase, infoStyles, labelStyles } from ".";
import { PoolTableProps } from "../../types";
import { UserProps } from "../../types/user";
import { FC, useEffect, useState } from "react";
import Show from "../common/show";
import EmptyView from "../common/EmptyView";
import { Api, getAbbr, numberWithCommas, showHideLoader } from "../../helpers";
import eventBus from "../../configs/eventsBus";

interface Props {
    User?: UserProps
    instantPlay?:boolean
}
interface StateProps {
    data: PoolTableProps[]
    loading: boolean
    searchVal: string | null
    searching: boolean
    refreshing: boolean
}
const Tables: FC<Props> = ({
    User,
    instantPlay
}) => {
    const navigate = useNavigate()
    const [state, setState] = useState<StateProps>({
        data: [],
        loading: true,
        searching: false,
        searchVal: null,
        refreshing: false
    })
    const { data, loading, searching, searchVal, refreshing } = state
    const location = useLocation();
    const { pricePerGame, _id, scanned } = location.state
    const getPoolTables = ({
        searchVal,
        loadingProp
    }: any) => {
        if (loadingProp)
            setState({ ...state, [loadingProp]: true })
        const afterProps = {
            searching: false,
            loading: false,
            searchVal,
            refreshing: false
        }
        Api({
            controller: 'Users',
            action: 'getPoolTables',
            searchVal,
            isCustomer: true,
            placeId: _id
        }).then((resp: any) => {
            setState({
                ...state,
                data: resp,
                ...afterProps
            })
        }).catch(() => {
            setState({
                ...state,
                ...afterProps
            })
        })
    }
    const ownerOpenPool = (tableId: string) => {
        showHideLoader(true, 'Opening pool table...')
        Api({
            controller: 'Payments',// isCustomer ? 'Payments' : 'Users',
            action: 'openPool',
            instantPlay,
            placeId: _id,
            tableId
        }).then(({ message, error, prompt }: any) => {
            showHideLoader(false)
            if (!error && !prompt)
                eventBus.dispatch('routes', { action: 'toastMsg', message, type: 'success' })
            //   if (isCustomer) {
            if (error || prompt)
                eventBus.dispatch('showAlert', {
                    title: error || prompt,
                    message,
                    buttons: error ? [
                        {
                            text: 'No',
                            backgroundColor: '#DCDCDC',
                            color: '#000'
                        },
                        {
                            text: 'Yes',
                            color: '#fff',
                            page:'/topup'
                        }
                    ] : [
                        {
                            text: 'Okay',
                            color: '#fff'
                        }
                    ]
                })
            else {
                navigate(-1)
                eventBus.dispatch('routes', { action: 'getUserData' })
            }
        }).catch(() => {
            showHideLoader(false)
            eventBus.dispatch('routes', { action: 'toastMsg', message: 'Something went wrong. Pool table not opened.', type: 'error' })
        })
    }

    useEffect(() => {
        getPoolTables({})
        return () => {
        }
    }, [])
    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 2
            }}>
                <Box
                    sx={{
                        flexDirection: 'row',
                        display: 'flex'
                    }}
                >
                    <IconButton
                        onClick={() => {
                            // navigate('/')
                            window.location.href = "/"
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>
                    <Typography sx={{
                        color: '#000',
                        fontSize: 25,
                        fontWeight: 'bold'
                    }}>Pool Tables</Typography>
                </Box>
            </Box>
            <Search sx={{
                borderRadius: 3
            }}>
                <SearchIconWrapper>
                    {/* <IconButton
                    onClick={()=>setState({...state, searchVal:''})}
                    >
                        <Cancel sx={{fontSize:20, color:'#A1A1A1'}} />
                    </IconButton> */}
                    <SearchIcon sx={{ fontSize: 20, color: 'black' }} />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder='Search'
                    inputProps={{ 'aria-label': 'search' }}
                // onChange={(e) => startSearch(e.target.value)}
                // value={searchVal}
                />
            </Search>
            <Typography sx={{ ...infoStyles, m: 1 }}><Typography style={labelStyles}>Balance Ksh</Typography> {numberWithCommas(String(User?.balance))}</Typography>
            <Typography sx={{ ...infoStyles, m: 1 }}><Typography style={labelStyles}>Price Per Game Ksh:</Typography> {numberWithCommas(pricePerGame)} </Typography>
            <Show>
                <Show.When isTrue={loading || searchVal && !data.length}>
                    <EmptyView
                        loading={loading}
                        oopTxt={'Oops!'}
                        text='You have not added any pool table'
                        searchValue={searchVal}
                    />
                </Show.When>
                <Show.Else>
                    <List>
                        {
                            data.map((item) => {
                                const { name, _id } = item
                                return (
                                    <Box key={_id}
                                        sx={{
                                            borderBottom: '1px solid #C7C7C7',
                                            mt: 2,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            p: 2
                                        }}
                                    >
                                        <Avatar alt={getAbbr(name)} sx={{ height: 70, width: 70 }}  >{getAbbr(name)}</Avatar>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                            alignItems: 'center',
                                            pl: 2,
                                            pr: 2
                                        }}>
                                            <Typography style={{
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                maxWidth: '80%',
                                                marginBottom: 5,
                                                color: '#000'
                                            }}>{name}</Typography>
                                            <Button
                                                onClick={() => ownerOpenPool(_id)}
                                                variant={'contained'}
                                                sx={{ textTransform: 'none' }}
                                            >Open</Button>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </List>
                </Show.Else>
            </Show>
        </Box>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(Tables)