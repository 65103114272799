import { Box, IconButton, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';


interface Props {
    title?: string
    goBack: () => void
}

const Header: FC<Props> = ({ title, goBack }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate()

    return (
        <Box sx={{ display: 'flex', alignSelf: 'start', position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1000, paddingY: 2, marginBottom: 5 }}>
            <IconButton onClick={() => goBack()}>
                < ArrowBackOutlinedIcon sx={{ display: 'flex', alignSelf: 'start', color: '#000' }} />
            </IconButton>

            <Typography variant='h5' sx={{ color: '#000', alignSelf: 'center', ml: 11 }}>{title}</Typography>

        </Box>
    )
}

export default Header;