import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import eventBus from '../../configs/eventsBus'
interface StateProps {
    text: string | null
    open: boolean
}

const Loader = () => {
    const [state, setState] = useState<StateProps>({
        open: false,
        text: null
    })
    const { open, text } = state
    const close = () => setState({ ...state, open: false })
    useEffect(() => {

        eventBus.on('loader', (info: any) => {
            let { action, text } = info.detail
            if (action === 'close')
                close()
            else
                setState({
                    ...state,
                    text,
                    open: true
                })
        })
        return () => {

        }
    }, [])
    return (
        <Modal
            open={open}
            onClose={close}
        >
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column'
            }}>
                <CircularProgress sx={{ color: '#fff' }} />
                <Typography sx={{color:'#fff', mt:2, fontSize:18}}>{text}</Typography>
            </Box>
        </Modal>
    )
}

export default Loader